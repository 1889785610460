<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div>
    <div v-if="userAcceptedDisclaimer == false">
      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <!--
              Background overlay, show/hide based on modal state.
              Entering: "ease-out duration-300"
                From: "opacity-0"
                To: "opacity-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100"
                To: "opacity-0"
            -->
          <div
            class="fixed inset-0 bg-landingPageBackground  transition-opacity"
            aria-hidden="true"
          ></div>
          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <!--
              Modal panel, show/hide based on modal state.
              Entering: "ease-out duration-300"
                From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                To: "opacity-100 translate-y-0 sm:scale-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100 translate-y-0 sm:scale-100"
                To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            -->
          <div
            class="inline-block align-bottom bg-white bg-opacity-10 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden  transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
          >
            <div>
              <div
                class="mx-auto block items-center justify-center h-12 w-12 rounded-full"
              >
                <img src="/privacyWarningAppleMusic.png" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3
                  class="text-lg leading-6 font-bold text-white"
                  id="modal-title"
                >
                  <span>Attention.</span>
                </h3>
                <div class="mt-2">
                  <p class="text-sm mb-5 mt-2 text-white font-bold">
                    <span
                      >You are about to link your Apple Music to a Discord
                      Account. If you were given this link by someone, DO NOT
                      ACCEPT.</span
                    >
                  </p>
                  <p class="text-sm text-white">
                    If you agree, your Apple Music account will be linked to
                    this Discord account. Anyone with access to your discord
                    account may access your playlists and other unintended
                    actions.
                  </p>
                  <p class="text-sm mb-5 mt-5 text-white font-bold">
                    <span
                      >Tunes.Ninja will not be able to see your Apple Music
                      account username or password.</span
                    >
                  </p>
                </div>
                <transition
                  enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                  enter-active-class="transform ease-out duration-300 transition"
                  enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  leave-to-class="opacity-0"
                  leave-active-class="transition ease-in duration-100"
                  leave-class="opacity-100"
                >
                  <div class="mt-4 flex justify-center" v-if="displayButtons">
                    <div class="pl-2">
                      <button
                        @click="userHasAcceptedDisclaimer"
                        type="button"
                        class="inline-flex pl-2 items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-green-900 bg-opacity-80 hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Accept.
                      </button>
                    </div>
                    <div class="pl-2">
                      <router-link to="/unauthorized/user-sent/message">
                        <button
                          type="button"
                          class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-red-900 bg-opacity-80 hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Decline.
                        </button>
                      </router-link>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="
        userToken == null &&
          userAcceptedDisclaimer == true &&
          userHasLinked == false
      "
    >
      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <!--
              Background overlay, show/hide based on modal state.
              Entering: "ease-out duration-300"
                From: "opacity-0"
                To: "opacity-100"f
              Leaving: "ease-in duration-200"
                From: "opacity-100"
                To: "opacity-0"
            -->
          <div
            class="fixed inset-0 bg-landingPageBackground  transition-opacity"
            aria-hidden="true"
          ></div>
          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <!--
              Modal panel, show/hide based on modal state.
              Entering: "ease-out duration-300"
                From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                To: "opacity-100 translate-y-0 sm:scale-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100 translate-y-0 sm:scale-100"
                To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            -->
          <div
            class="inline-block align-bottom bg-white bg-opacity-10 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden  transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
          >
            <div>
              <div
                class="mx-auto block items-center justify-center h-12 w-12 rounded-full"
              >
                <img src="/mechArm.png" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3
                  class="text-lg leading-6 font-bold text-white"
                  id="modal-title"
                >
                  <span v-if="hasAppleMusicClientInitalized"
                    >Link Apple Music</span
                  >
                  <span v-else-if="issueLinkingToAppleMusic"
                    >There was a problem.</span
                  >
                </h3>
                <div class="mt-2">
                  <p
                    class="text-sm text-white"
                    v-if="issueLinkingToAppleMusic == true"
                  >
                    {{ issueLinkingToAppleMusicMessage }}
                  </p>
                  <p
                    class="text-sm text-white"
                    v-else-if="hasAppleMusicClientInitalized"
                  >
                    Click the button below to link your Apple Music account.
                  </p>
                  <p class="text-sm text-white" v-else>
                    {{ loadingMessage }}
                  </p>
                </div>
                <div class="mt-4">
                  <button
                    v-if="hasAppleMusicClientInitalized"
                    @click="promptUserAuthorizationAndSaveToken"
                    type="button"
                    class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-white bg-opacity-10 hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Click here to link with Apple Music
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="
        userToken != null &&
          userAcceptedDisclaimer == true &&
          userHasLinked == true
      "
    >
      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
          <div
            class="fixed inset-0 bg-landingPageBackground  transition-opacity"
            aria-hidden="true"
          ></div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >

          <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
          <div
            class="inline-block align-bottom bg-white bg-opacity-10 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden  transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
          >
            <div>
              <div
                class="mx-auto block items-center justify-center h-12 w-12 rounded-full"
              >
                <img src="/mechArm.png" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3
                  class="text-lg leading-6 font-bold text-white"
                  id="modal-title"
                >
                  Linked up.
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-white">
                    Your Apple Music is linked. Give a few minutes before
                    linking your playlist and or doing any Apple Music-related
                    actions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <error-linking-to-apple-music
      v-if="issueLinkingToAppleMusic"
      :errorMessage="issueLinkingToAppleMusicMessage"
    ></error-linking-to-apple-music>
  </div>
</template>
<script>
import fetch from "node-fetch";
import ErrorLinkingToAppleMusic from "../components/ErrorLinkingToAppleMusic.vue";

export default {
  components: { ErrorLinkingToAppleMusic },
  name: "LinkAppleMusic",
  data() {
    return {
      userHasLinked: false,
      userAcceptedDisclaimer: false,
      developerToken: null,
      amAPIBuildName: null,
      hasAppleMusicClientInitalized: false,
      hasLinkedToAppleMusic: false,
      issueLinkingToAppleMusic: false,
      userToken: null,
      issueLinkingToAppleMusicMessage:
        "There was an unknown error trying to start the process of linking. Disable any extensions that may interfere with this website and try again. If this continues to happen, contact support.",
      loadingMessage: "Give us a moment. Do not refresh this page.",
      displayButtons: false,
      userClient: null,
    };
  },

  methods: {
    async requestClientIntializationRequirements() {
      const requestDeveloperToken = await fetch(
        `https://api.tunes.ninja/apple-music/developer-token`
      );
      const developerInformation = await requestDeveloperToken.json();
      return developerInformation;
    },
    async initialzeAppleMusicClient() {
      window.MusicKit.configure({
        developerToken: this.developerToken,
        app: {
          name: "TunesNinja-Development",
          version: "0.0.1b",
        },
      });
      const client = window.MusicKit.getInstance();
      this.userClient = client;
      //await this.promptUserAuthorizationAndSaveToken(client);
    },
    async isAppleMusicLinked() {
      return window.MusicKit.getInstance().isAuthorized;
    },
    async promptUserAuthorizationAndSaveToken() {
      let self = this;
      const linkingCode = this.$route.params.uniqueLinkingCode;
      window.MusicKit.getInstance()
        .authorize()
        .then((response) => {
          self.userToken = response;
          console.log(self.userToken);

          try {
            fetch("https://api.tunes.ninja/internal/link/apple-music", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                personalToken: response,
                linkingCode: linkingCode,
              }),
            }).then((res) => {
              if (!res.ok) {
                if (
                  res.json().detail.status == "error" &&
                  res.json().detail.reason
                ) {
                  this.issueLinkingToAppleMusicMessage = res.json().detail.reason;
                  this.issueLinkingToAppleMusic = true;
                }
                this.issueLinkingToAppleMusicMessage =
                  "There was an unexpected result from linking your acount. Please try again.";
                this.issueLinkingToAppleMusic = true;
              } else {
                this.userHasLinked = true;
                console.log("done");
              }
            });
          } catch (error) {
            this.issueLinkingToAppleMusic = true;
            this.issueLinkingToAppleMusicMessage =
              "There was a problem linking up. Please check your internet connection and try again.";
          }
        });
    },
    async userHasAcceptedDisclaimer() {
      this.userAcceptedDisclaimer = true;
      try {
        if (this.userAcceptedDisclaimer == true) {
          await this.initialzeAppleMusicClient();
          this.hasAppleMusicClientInitalized = true;
        }
      } catch (error) {
        this.issueLinkingToAppleMusic = true;
        this.issueLinkingToAppleMusicMessage =
          "There was a problem. Please contact support with this code: Far-Vegas";
      }
    },
  },

  async mounted() {
    setTimeout(() => {
      this.displayButtons = true;
    }, 1);

    if (!window.MusicKit) {
      this.loadingMessage =
        "Hold on, there was an unexpected result. Give us a second.";
      setTimeout(() => {
        this.$router.go();
      }, 5000);
    } else {
      console.log("music kit loaded in");

      const clientInfo = await this.requestClientIntializationRequirements();
      this.developerToken = clientInfo.developer_token;
      try {
        if (this.userAcceptedDisclaimer == true) {
          await this.initialzeAppleMusicClient();
          this.hasAppleMusicClientInitalized = true;
        }
      } catch (error) {
        this.issueLinkingToAppleMusic = true;
        this.issueLinkingToAppleMusicMessage =
          "There was a problem. Please contact support with this code: Far-Vegas";
      }
    }
  },
};
</script>

<style></style>
